<style scoped>
/*头部公共样式结束*/
/* .detect-line {
  margin-top: 200px;
} */
.detect-line >>> .el-card__header {
  border: none;
}
.detect-line .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.detect-line .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  top: 50px;
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
  margin-top: 60px;
}
.card-content {
  /* width: 1116px; */
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
}
.detect-line .content {
  padding: 0 65px 40px;
  box-sizing: border-box;
}
.detect-line .content .search-box {
  display: flex;
  justify-content: space-between;
}
/*头部公共样式结束*/
.detect-line .content .content-box {
  margin-top: 20px;
  width: 100%;
}
.detect-line .content .content-box .echarts-box {
  /* width: 1070px; */
  height: 330px;
  width: 100%;
  /* border: 1px solid #000; */
}
</style>
<template>
  <div class="detect-line" id="container">
    <el-card>
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="back"
        >
          返回
        </el-button>
        <p class="card-title">{{ patient_project_item }}</p>
      </div>
      <div class="content">
        <div class="search-box">
          <date-picker
            @dateChange="dateChange"
            @typeChange="typeChange"
            :type="time_type"
            :dateValue="from_time"
          ></date-picker>
          <el-button
            icon="el-icon-download"
            type="primary"
            size="medium"
            plain
            @click.stop="download"
            >下载结果</el-button
          >
        </div>
        <el-empty v-if="isShow" :image="emptyImg" image-size="400">
          <template slot="description">
            <span style="color: #999"> 没有查询到相应内容 </span>
          </template>
        </el-empty>
        <div class="content-box" v-else>
          <div class="echarts-box">
            <echarts-line :data="list" :titleText="titleText"></echarts-line>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import EchartsLine from "@/components/Line/Line.vue";
import { ownerProjectItemTrend, ownerStatExport } from "@/api/base";

export default {
  name: "detectLine",
  components: {
    DatePicker,
    EchartsLine,
  },
  data() {
    return {
      emptyImg: require("@/assets/images/empty.png"),
      isShow: false,
      patient_project_name: null,
      operation_phone: null,
      time_type: null,
      from_time: null,
      to_time: null,
      patient_project_item: null,
      list: [],
      count: null,
      titleText: null,
      device_id: null,
    };
  },
  created() {
    this.device_id = this.$route.query.device_id;
    this.patient_project_name = this.$route.query.patient_project_name;
    this.operation_phone = this.$route.query.operation_phone;
    this.time_type = this.$route.query.time_type;
    this.from_time = this.$route.query.from_time;
    this.to_time = this.$route.query.to_time;
    this.patient_project_item = this.$route.query.patient_project_item;
    this.getList();
  },
  methods: {
    //获取列表详情
    async getList() {
      let {
        time_type,
        to_time,
        from_time,
        device_id,
        operation_phone,
        patient_project_item,
        patient_project_name,
      } = this;
      let res = await ownerProjectItemTrend({
        time_type,
        from_time,
        to_time,
        operation_phone,
        device_id,
        patient_project_item,
        patient_project_name,
      });
      this.list = res.data.records;
      //计算count总和
      let newList = [];
      this.list.forEach((item) => newList.push(item.count));
      this.count = newList.reduce(function (prev, curr) {
        return prev + curr;
      });
      if (this.time_type == 1) {
        this.titleText = {
          title: `${that.data.from_time}至${that.data.to_time}`,
          subTitle: `检测统计：${this.count}个`,
        };
      } else if (this.time_type == 2) {
        this.titleText = {
          title: `${from_time.replace("-", "年")}月`,
          subTitle: `检测统计：${this.count}个`,
        };
      } else {
        this.titleText = {
          title: `${from_time}年`,
          subTitle: `检测统计：${this.count}个`,
        };
      }
    },
    //返回
    back() {
      this.$router.back();
    },

    //时间类型选择
    typeChange(e) {
      this.time_type = e;
    },
    //时间选择器返回值 e.dateValue  e.selectValue
    dateChange(e) {
      if (!this.time_type) {
        this.time_type = "3";
      }
      this.from_time = e.from_time;
      this.to_time = e.to_time;
      this.getList();
    },
    //下载
    async download() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let {
        patient_project_name,
        device_id,
        from_time,
        to_time,
        time_type,
        operation_phone,
        patient_project_item,
      } = this;
      const res = await ownerStatExport({
        patient_project_item,
        device_id,
        patient_project_name,
        operation_phone,
        from_time,
        to_time,
        time_type,
        flag: 1,
      });
      if (res) {
        const blob = new Blob([res]);
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${patient_project_item}.xlsx`);
        link.click();
        link = null;
      }
      loading.close();
    },
  },
};
</script>
